body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.text-field-input {
  font-family: Arial !important;
}

li {
  font-family: Montserrat !important;
}

.app-font {
  font-family: Montserrat !important;
}

/* p {
  font-family: Montserrat !important;
}
button {
  font-family: Montserrat !important;
}
span {
  font-family: Montserrat !important;
} */

.pointer {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.demo-root>span {
  color: rgb(255 255 255 / 60%);
}

.demo-root1>span {
  color: rgb(0 0 0 / 50%);
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 11.275rem;
  height: 0.375rem;
}

*::-webkit-scrollbar-track {
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.75);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.36);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .5);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.description {
  padding: 10px;
}

.target {
  position: absolute;
  width: 320px;
  height: 240px;
  top: 150px;
  left: 100px;
  line-height: 100px;
  text-align: center;
  /* background: #ee8; */
  color: #333;
  font-weight: bold;
  border: 1px solid #333;
  box-sizing: border-box;
}

.container-scroll {
  display: flex;
  /* flex-direction: column; */
  position: relative;
  top: 5px;
  left: 5px;
  width: calc(100vw - 29.8rem);
  height: calc(100vh - 16rem);
}

@media (max-width: 600px) {
  .container-scroll {
    width: 100dvw;
    height: calc(100dvh - 13.25rem);
    left: 0px;
    top: 16px;
  }

  .scroll-container {
    display: flex;
    position: relative;
    width: 100dvw;
    height: calc(100dvh - 13.25rem);
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
  }

  .moveable-rotation-control {
    width: 18px !important;
    height: 18px !important;
    margin-top: -16px !important;
    margin-left: -9px !important;
    cursor: alias;
    padding: 2px !important;
    border: none !important;
    background-color: #f0f0f0 !important;
    background-image: url("./assets/icons/rotate.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    box-shadow: 0px 0px 2px black !important;
  }

  .moveable-direction::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 2.5rem;
    width: 2.5rem; */
    width: 100%;
    min-width: 1.2rem;
    max-width: 2.25rem;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  .active-moveable-direction::before {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.101);
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.editor-background {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.1503195028011205) 50%, rgba(0, 0, 0, 0) 100%);
}

.infinite-viewer-wrapper {
  display: flex !important;
  justify-content: center !important;
}

.font-size {
  width: 50px;
  padding: 8px;
  text-align: center;
  border: 1px solid #707070;
}

.font-size:focus-visible {
  outline: unset !important
}

.font-size-slider {
  width: 35px;
  padding: 3px;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #707070;
}

.font-size-slider-text {
  width: 35px;
  padding: 3px;
  background-color: transparent;
  text-align: center;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #ffffff;
}

.font-size-slider::-webkit-outer-spin-button,
.font-size-slider::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.font-size-slider-text::-webkit-outer-spin-button,
.font-size-slider-text::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.container {
  display: flex;
  overflow: hidden;
  /* flex-direction: column; */
  position: relative;
  /* top: 5px;
  left: 5px; */
  width: calc(100vw - 39rem);
  height: calc(100vh - 16rem);
  /* width: 800px;
  height: 450px; */
  /* border: 1px solid #333; */
  /* box-sizing: border-box; */
  background-color: white;
  box-shadow: rgb(136, 136, 136) 1px 2px 4px;

}

@media (max-width: 600px) {
  .container {
    width: calc(100dvw - 1.5rem);
    height: calc(100dvh - 15.5rem);
  }
}

.container-preview {
  display: flex;
  overflow: hidden;
  align-self: center;
  position: relative;
  width: calc(100vw);
  height: calc(100vh);
}

.MuiCircularProgress-determinate {
  color: #f7921e !important;
}

#canvas-container:hover {
  outline: 2px solid #A020F0;
  /* border: 2px solid #A020F0; */
  /* border-right : 2px solid #A020F0; */
  /* border-bottom: 2px solid #A020F0;
  border-top: 2px solid #A020F0;
  border-left: 2px solid #A020F0; */
}

#react-select-3-listbox {
  z-index: 1112 !important;
}

#react-select-3-listbox>div {
  z-index: 11112 !important;
}

.editor-center {
  display: flex;
  justify-content: center;
}

.target {
  position: absolute;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #ee8;
  color: #333;
  font-weight: bold;
  border: 1px solid #333;
  box-sizing: border-box;
  z-index: 1000;
}

.target2 {
  position: absolute;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  background: #ee8;
  color: #333;
  font-weight: bold;
  border: 1px solid #333;
  box-sizing: border-box;
  z-index: 1000;
}

.text-input {
  /* position: absolute;
  left: 0;
  top: 0; */
  color: black;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  outline: 2px solid #8b3eff;
  background: none;
  border: none;
  font-size: inherit;
}

.textarea-input {
  /* position: absolute;
  left: 0;
  top: 0; */
  color: black;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-size: inherit;
}

@media (max-width: 600px) {
  .text-input {
    outline: 1px solid #8b3eff;
  }

  .selectableGroupItemActive {
    outline: 1px solid #8b3eff;
  }

  .text-border-active {
    outline: 1px solid #8b3eff;
    border: none;
  }

  .text-border {
    border: none;
    outline: none;
  }

  .border-custom {
    outline: 1px solid #8b3eff !important;
    border: none;
  }

  .border-custom-group-select {
    outline: 1px solid #8b3eff;
    border: none;
  }
}

.border-custom {
  outline: 2px solid #8b3eff !important;
  /* outline: 1px solid rgb(70, 166, 249); */
  border: none;
}

.border-custom-sub {
  background-color: rgba(0, 0, 0, .08);
  border: none;
}

.border-custom-group-select {
  outline: 2px solid #8b3eff;
  border: none;
}

.text-input-type {
  /* position: absolute;
  left: 0;
  top: 0; */
  color: white;
  /* width: 100%; */
  height: 100%;
  padding: 0;
  margin: 0;
  margin-left: 0.75rem;
  border: none;
  background: none;
  outline: none;
  font-size: inherit;
}

.video-hover:hover {
  opacity: 0.8 !important;
}

/* .video-hover:hover::after {
  content: "Click to play/push video";
} */



.scroll-textarea::-webkit-scrollbar {
  display: none;
}


.main-image {
  grid-area: image;
  background-image: url("https://source.unsplash.com/EwKXn5CapA4");
  width: 200px;
  height: 279px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.main-image1 {
  width: 200px;
  height: 279px;
}


.sidebar {
  grid-area: sidebar;
  /* background-color: hsl(265, 100%, 86%); */
  /* border-left: 1px solid hsl(265, 100%, 56%); */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sidebar-item {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  /* background-color: hsl(265, 100%, 86%); */
  padding: 1rem;
  position: relative;
  transition: background-color 150ms;
}

.sidebar-item:hover,
.sidebar-item:focus {
  /* background-color: hsl(265, 100%, 76%); */
}

.sidebar-item.active {
  background-color: hsl(265, 100%, 70%);
}

.sidebar-item::after {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
  background-color: hsl(265, 100%, 46%);
}

.sidebar-item:last-child::after {
  display: none;
}

.slider-container {
  grid-area: slider;
  margin-top: 2rem;
  padding: 2rem;
}

.slider {
  width: 100%;
  cursor: pointer;
}


.selectAsyncBox {
  color: black !important;
  /* z-index: 999; */

  :nth-child(4) {
    color: black;

    &:hover {
      box-shadow: 0rem 0rem 0rem 0rem black !important;
    }

    div {
      background-color: #E5E5E5;
      color: black;

      div {
        min-height: 2.5rem;
        margin: 0 !important;
        border-radius: 0;
        display: flex;
        align-items: center;
        padding: 0.5rem !important;
        line-height: 1.2rem;

        &:hover {
          background-color: black;
          color: white;
        }
      }
    }
  }

  div:nth-child(1) {

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :last-child {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
}

.width-65 {
  width: 65% !important;
}

.width-100 {
  width: 100% !important;
}

.width-55 {
  width: 55% !important;
}

.height-select-dd {
  height: 40px !important;
}

.height-select-dd>div {
  margin-top: 0rem !important;
  padding: 0rem !important;
}

.height-select-dd {
  color: black !important;
  /* z-index: 999; */

  :nth-child(4) {
    color: black;

    &:hover {
      box-shadow: 0rem 0rem 0rem 0rem black !important;
    }

    div {
      background-color: #3e3e3e !important;
      color: white !important;

      div {
        min-height: 2.5rem;
        margin: 0 !important;
        border-radius: 0;
        display: flex;
        align-items: center;
        padding: 0.5rem !important;
        line-height: 1.2rem;

        &:hover {
          background-color: black !important;
          color: white;
        }
      }
    }
  }

  div:nth-child(1) {

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :last-child {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
}

.MuiButtonBase-root {
  font-family: Montserrat !important;
}

/* .infinite-viewer-horizontal-scroll-bar {
  display: none !important;
}
.infinite-viewer-vertical-scroll-bar {
  display: none !important;
} */

/* .infinite-viewer-scroll-thumb {
  pointer-events: none;
} */

.selectAsyncBox>div {
  margin-top: 0.33rem;
  border-radius: 0.625rem;
  border-color: black;
  box-shadow: 0rem 0rem 0rem 0rem black;

  &:hover {
    border-color: black;
    box-shadow: 0rem 0rem 0rem 0.0625rem black !important;
    cursor: pointer;
  }
}

.selectAsyncBox>div>div>div {
  font-family: Montserrat !important;
  border-radius: 0.625rem;
}

.selectAsyncBox>div {
  padding: 0.3rem;
}

.selectAsyncBox>div>div>div>div {
  border-radius: 0.625rem;
}

.selectAsyncBox>div>div>div>div>input {
  font-family: Montserrat !important;
  font-size: 1rem !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul[role=listbox] {
  max-height: 20rem;
  padding-top: 0;
  padding-bottom: 0;
}

.Mui-checked {
  color: #f7921e !important;
}

.MuiSwitch-track {
  background-color: #f6872c !important;
}

.text-layout-1 {
  flex-flow: wrap !important;
  justify-content: unset !important;
  column-gap: 4px !important;
}

.text-layout-1>.layout-title {
  width: auto !important
}

.text-layout-1>.layout-price {
  width: auto !important
}

.text-layout-1>.layout-description {
  width: 100% !important
}

.text-layout-2 {
  flex-flow: wrap !important
}

.text-layout-div {
  float: left;
}

@media (min-width: 600px) {
  .text-layout-div:hover {
    background-color: rgb(112, 112, 112, 0.4) !important;
    cursor: pointer;
  }
}

.text-layout-active {
  background-color: rgb(112, 112, 112, 0.4) !important;
  animation: blinker 1s linear 5 !important;
  cursor: pointer;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.text-layout-2>.layout-title {
  max-width: 60% !important;
  /* width: 60% !important */
  width: auto !important;
}

.text-layout-2>.layout-title>div {
  justify-content: left !important;
}

.text-layout-2>.layout-price {
  width: 40% !important;
  text-align: right !important
}

.text-layout-3 {
  flex-flow: wrap !important
}

.text-layout-3>.layout-title {
  width: auto !important;
  flex: 1 !important;
  display: flex !important;
}

.text-layout-3>.layout-price {
  /* flex-grow: 1; */
  text-align: right;
  display: flex;
  width: auto !important
}

.text-layout-3>.layout-description {
  width: 100% !important
}

.text-layout-3>.layout-title::after {
  content: " ";
  flex: 1;
  border-bottom: 1px dotted var(--borderBottomColor, black);
  ;
}

/* .text-layout-3>.layout-price::before {
  content: '';
  background: repeating-linear-gradient(to right, currentColor, currentColor 1px, transparent 2px, transparent 4px);
  height: 1px;
  flex-grow: 1;
  display: inline-block;
  margin-top: 1em;
}
 */

.ruler {
  position: absolute;
  top: 0;
  left: 0;
}

.ruler.horizontal {
  left: 0px;
  width: 100%;
  height: 30px;
}

.ruler.vertical {
  top: 0px;
  width: 30px;
  height: 100%;
}

@media (max-width: 600px) {
  .ruler.horizontal {
    display: none !important;
  }

  .ruler.vertical {
    display: none !important;
  }
}

.custom-button {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
  background: #8b3eff;
  font-family: Montserrat;
  border-radius: 4px;
  appearance: none;
  border: 0;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-layout-4 {
  flex-flow: wrap !important
}

.text-layout-4>.layout-title {
  max-width: 65% !important;
  min-width: fit-content !important;
  /* min-width: 65% !important; */
  width: auto !important;
}

.text-layout-4>.layout-title>div {
  justify-content: left !important;
}

.text-layout-4>.layout-price {
  width: auto !important;
  max-width: 35% !important;
  text-align: right;
}

.text-layout-4>.layout-description {
  width: 70% !important
}

.text-layout-5 {
  flex-flow: wrap !important;
  justify-content: center !important;
  column-gap: 4px !important;
}

.text-layout-5>.layout-title {
  width: auto !important
}

.text-layout-5>.layout-price {
  width: auto !important
}

.text-layout-5>.layout-description {
  width: 100% !important;
  text-align: center;
}

.layout-center_layout {
  text-align: center !important;
}

.layout-bottom_price_center_layout {
  text-align: center !important;
}

.layout-end_price_center_layout {
  text-align: center !important;
}

.layout-right_layout {
  text-align: right;
  width: 100% !important;
}

.layout-bottom_price_right_layout {
  text-align: right !important;
  width: 100% !important;
}

.text-layout-6 {
  flex-flow: wrap !important;
  justify-content: right !important;
  column-gap: 4px !important;
}

.text-layout-6>.layout-title {
  width: auto !important
}

.text-layout-6>.layout-price {
  width: auto !important
}

.text-layout-6>.layout-description {
  width: 100% !important;
  text-align: right;
}

.text-layout-7 {
  flex-flow: wrap !important
}

.text-layout-7>.layout-title {
  width: 100% !important
}

.text-layout-7>.layout-title>div {
  justify-content: space-between !important;
}

.text-layout-7>.layout-price {
  /* width: auto !important; */
  /* height: max-content !important; */
  display: flex;
  align-self: end;
}

.text-layout-7>.layout-description {
  /* width: 70% !important */
}

.text-layout-8 {
  flex-flow: wrap !important
}

.text-layout-8>.layout-title {
  width: 100% !important
}

.text-layout-8>.layout-title>div {
  justify-content: space-between !important;
}

.text-layout-8>.layout-price {
  /* width: 30% !important; */
  text-align: center !important;
  height: max-content !important;
}

/* .text-layout-8>.layout-description {
  width: 70% !important
} */

.text-layout-9 {
  flex-flow: wrap !important;
  justify-content: unset !important;
  column-gap: 4px !important;
}

.text-layout-9>.layout-title {
  max-width: 100% !important;
  width: auto !important;
}

.text-layout-9>.layout-title>div {
  justify-content: left !important;
}

.text-layout-9>.layout-price {
  width: auto !important
}

.text-layout-9>.layout-description {
  width: auto !important
}

.text-layout-10 {
  flex-flow: wrap !important;
}

.text-layout-10>.layout-title {
  max-width: 100% !important;
  width: auto !important;
}

.text-layout-10>.layout-title>div {
  justify-content: left !important;
}

.text-layout-10>.layout-price {
  width: auto !important
}

.text-layout-10>.layout-description>.layout-price {
  width: auto !important;
  float: right !important;
}

.text-layout-10>.layout-description {
  width: 100% !important;
  display: inline-block;
}

.text-layout-11 {
  flex-flow: wrap !important;
  justify-content: center !important;
  column-gap: 4px !important;
}

.text-layout-11>.layout-title {
  max-width: 100% !important;
  width: auto !important;
  text-align: center;
}

.text-layout-11>.layout-title>div {
  justify-content: center !important;
}

.text-layout-11>.layout-price {
  width: auto !important
}

.text-layout-11>.layout-description {
  width: auto !important;
  text-align: center;
}

.text-layout-12 {
  flex-flow: wrap !important;
  justify-content: right !important;
  column-gap: 4px !important;
}

.text-layout-12>.layout-title {
  max-width: 100% !important;
  width: auto !important;
  text-align: right;
}

.text-layout-12>.layout-title>div {
  justify-content: end !important;
}

.text-layout-12>.layout-price {
  width: auto !important
}

.text-layout-12>.layout-description {
  width: auto !important;
  text-align: right;
}

.text-layout-13 {
  flex-flow: wrap !important;
  justify-content: center !important;
  column-gap: 4px !important;
}

.text-layout-13>.layout-title {
  width: auto !important;
  text-align: center !important;
}

.text-layout-13>.layout-title>div {
  justify-content: center !important;
}

.text-layout-13>.layout-price {
  width: auto !important;
  text-align: center;
}

.text-layout-13>.layout-description {
  width: 100% !important;
  text-align: center;
}

.text-layout-14 {
  flex-flow: wrap !important;
  justify-content: left !important;
  column-gap: 4px !important;
}

.text-layout-14>.layout-title {
  max-width: 100% !important;
  width: auto !important;
  text-align: left;
}

.text-layout-14>.layout-title>div {
  justify-content: left !important;
}

.text-layout-14>.layout-price {
  width: auto !important;
  text-align: left;
}

.text-layout-14>.layout-description {
  width: 100% !important;
  text-align: left;
}

.text-layout-15 {
  flex-flow: wrap !important;
  justify-content: left !important;
  column-gap: 4px !important;
}

.text-layout-15>.layout-title {
  width: auto !important;
  text-align: left;
}

.text-layout-15>.layout-price {
  width: auto !important;
  text-align: left;
}

.text-layout-15>.layout-description {
  width: auto !important;
  text-align: left;
}


.layout-mix_center_layout {
  text-align: center;
}

.text-layout-16 {
  flex-flow: wrap !important;
  justify-content: center !important;
  column-gap: 4px !important;
}

.text-layout-16>.layout-title {
  width: auto !important;
  text-align: center;
}

.text-layout-16>.layout-price {
  width: auto !important;
  text-align: center;
}

.text-layout-16>.layout-description {
  width: auto !important;
  text-align: center;
}

/* Insert a space before the content */
/* .layout-description::after {
  content: ' ' attr(data-price);
} */

.dialogBoxOnB .MuiPaper-root {
  border-radius: 1rem;
}

/* dnd */
.react-grid-dnd-drop-zone {
  gap: 15px;
  /* Set the maximum gap size between grid items */
}

.moveable-rotation {
  height: calc(25px* 1) !important;
}

*::-webkit-scrollbar {
  width: 0.225rem;
  height: 0.225rem;
}

*::-webkit-scrollbar-track {
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.06);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.16);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.36);
}

*::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, .5);
}

.color-view:hover {
  border-radius: 4px;
  border: 2px solid #d3d3d3;
  transition: box-shadow .2s ease-in-out;
}

.moveable-control {
  background-color: white !important;
  box-shadow: 0px 0px 2px black !important;
}

.moveable-control:hover {
  background-color: #8b3eff !important;
  border: 0px !important
}

/* .moveable-control.moveable-e {
  border-radius: 25% !important;
  height: 16px !important; 
  margin-top: -8px !important
}

.moveable-control.moveable-w {
  border-radius: 25% !important;
  height: 16px !important; 
  margin-top: -8px !important;
} */

.text-hide-layout:hover {
  opacity: 0.5 !important;
  text-wrap: nowrap !important;
  overflow: hidden !important;
}

.text-hide-layout {
  text-wrap: nowrap !important;
}

.moveable-rotation-line {
  display: none !important;
}

@media (min-width: 600px) {
  .moveable-rotation-control {
    border-color: white !important;
    width: 24px !important;
    height: 24px !important;
    margin-top: -21px !important;
    margin-left: -12px !important;
    cursor: alias;
    padding: 2px !important;
    border: none !important;
    background-color: #f0f0f0 !important;
    background-image: url("./assets/icons/rotate.svg") !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    box-shadow: 0px 0px 2px black !important;
  }
}

.moveable-line {
  background-color: #8b3eff !important;
}

.text-char {
  display: inline;
  margin: 0 2px;
  /* Adjust margin as needed */
  vertical-align: middle;
}

/* .rCS1otmaw7 .infinite-viewer-scroll-thumb {
  background: none !important;
} */

.qrcode {
  width: 90% !important;
  height: 90% !important;
}

.no-outline {
  outline: none !important
}

.pinch-zoom-container {
  width: 100%;
}

html,
body {
  touch-action: pan-x pan-y;
}

* {
  touch-action: pan-x pan-y;
}

.selectableGroupItemActive {
  outline: 2px solid #8b3eff;
}

html,
body {
  overscroll-behavior: none;
}

.instant-menu-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
}

.rde-icon-container .rde-icon-top:hover,
.rde-icon-container .rde-icon-top:focus {
  background-color: rgba(0, 0, 0, 0) !important;
}

.menu-option {
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 36%, rgba(247,146,29,1) 100%); */
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 36%, rgb(255 227 194) 100%)
}

input[type=file]::file-selector-button {
  border: 2px solid #ec8209;
  padding: .2em .4em;
  border-radius: .2em;
  background-color: #f7921e;
  color: white;
  transition: 1s;
  cursor: pointer;
}


.blur-image-loading {
  filter: blur(4px) !important;
  transition: filter 0.5s ease-in-out;
}

.element-icon {
  color: #ffffff;
}

.element-icon:hover {
  color: #dcdada;
}